import React from 'react';

// components
import { Vector } from 'components';

// assets
import halfCircle from 'assets/logo-elements/half-circle.svg';
import triangle from 'assets/logo-elements/triangle.svg';
import circle from 'assets/logo-elements/circle.svg';
import plus from 'assets/elements/plus-red.svg';
import sum from 'assets/elements/sum.svg';

// animations
import { animateVectorParallax } from 'sections/animations';

export const HalfCircleShape = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, 0, 300, 20);

    return killAnimation;
  }, []);

  return (
    <Vector
      vectorRef={vectorRef}
      svg={halfCircle}
      alt=""
      sx={{
        position: 'absolute',
        top: ['10%', '-47%', '-58%', '61%', '56%', '92%'],
        left: ['13%', '77%', '69%', '-29%', '-37%', '-57.5%'],
        transform: 'rotate(175deg)',
        width: ['10%', '10%', '10%', '10%', '7%', '10%']
      }}
    />
  );
};

export const TriangleShape = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, -100, 300, 120);

    return killAnimation;
  }, []);

  return (
    <Vector
      vectorRef={vectorRef}
      svg={triangle}
      alt=""
      sx={{
        position: 'absolute',
        top: ['16%', '-15%', '-16%', '-16%', '-64%', '-12%'],
        left: ['10%', '23%', '5%', '5%', '3%', '-9.5%'],
        transform: 'rotate(310deg)',
        width: ['12%', '8%', '7%', '7%', '7%', '7%']
      }}
    />
  );
};

export const PlusShape = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, 0, 200, 360);

    return killAnimation;
  }, []);

  return (
    <Vector
      vectorRef={vectorRef}
      svg={plus}
      alt=""
      sx={{
        position: 'absolute',
        top: ['-35%', '-68%', '-66%', '18%', '-47%', '50%'],
        right: ['12%', '88%', '82%', '9%', '14%', '-11%'],
        width: ['12%', '8%', '7%', '7%', '7%', '6%']
      }}
    />
  );
};

export const CircleShape = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, 50, 300, 0);

    return killAnimation;
  }, []);

  return (
    <Vector
      vectorRef={vectorRef}
      svg={circle}
      alt=""
      sx={{
        position: 'absolute',
        top: ['28%', '16%', '-18%', '17%', '16.5%', '-20%'],
        right: ['3%', '18%', '90%', '19%', '20%', '-0.5%'],
        width: ['14%', '8%', '6%', '8%', '6%', '7.2%']
      }}
    />
  );
};

export const SumShape = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, -20, 150, 30);

    return killAnimation;
  }, []);

  return (
    <Vector
      vectorRef={vectorRef}
      svg={sum}
      alt=""
      sx={{
        position: 'absolute',
        right: ['9%', '39%', '60%', '16%', '31%', '163%'],
        bottom: ['-14%', '-38%', '-40%', '-30%', '-25%', '41%'],
        width: ['17%', '10%', '8%', '9%', '8%', '12%']
      }}
    />
  );
};
