import gsap from 'gsap/all';

export const introHeaderAnimation = (refs, handleTextTransform) => {
  let tl = gsap.timeline();

  tl.to(refs.headlineRef.current, {
    autoAlpha: 1,
    delay: 0.5,
    duration: 0.8,
    onComplete: handleTextTransform
  })
    .fromTo(
      refs.circleElementRef.current,
      { x: -50 },
      { x: 0, autoAlpha: 1, duration: 0.8 }
    )
    .fromTo(
      refs.triangleElementRef.current,
      { y: -50, rotate: 260 },
      { y: 0, rotate: 0, autoAlpha: 1, duration: 0.8 },
      '<'
    )
    .fromTo(
      refs.halfCircleElementRef.current,
      { x: 50, rotate: 360 },
      { x: 0, rotate: 0, autoAlpha: 1, duration: 0.8 },
      '<'
    )
    .to(
      [
        '#nav',
        refs.infoRef.current,
        refs.quesAndExcRef.current,
        refs.arrowRef.current
      ],
      {
        autoAlpha: 1
      }
    );

  const killAnimation = () => {
    tl.pause(0).kill(true);
  };

  return killAnimation;
};
