import React from 'react';

// pieces
import { Section, Heading, Box, Paragraph } from '@thepuzzlers/pieces';

// components
import { Vector } from 'components';

// assets
import redStarSvg from 'assets/facts/red-star.svg';
import yellowTriangleSvg from 'assets/facts/yellow-triangle.svg';

// animations
import { animateVectorParallax, fadeInText } from 'sections/animations';

export const Facts = ({ data: { heading, factList } }) => {
  const textBlocksRef = React.useRef([]);
  textBlocksRef.current = factList.map(
    (ref, i) => textBlocksRef.current[i] ?? React.createRef()
  );

  React.useEffect(() => {
    textBlocksRef.current.map((element) => {
      const killAnimation = fadeInText(element);

      return killAnimation;
    });
  }, []);

  return (
    <Section id="facts">
      <Headline heading={heading} />
      {factList.map((fact, index) => (
        <TextBlock
          key={`factList-${index}`}
          {...fact}
          designatedRef={textBlocksRef.current[index]}
          sx={factListStyles[index]}
        />
      ))}
      <SectionDecorationSet />
    </Section>
  );
};

const Headline = ({ heading }) => (
  <Heading
    className="facts__headline"
    as="h2"
    type="h2-900-125"
    dangerouslySetInnerHTML={{ __html: heading }}
    sx={{
      gridColumn: [
        '1 / span 12',
        '1 / span 8',
        '3 / span 15',
        '1 / span 16',
        '2 / span 12',
        '3 / span 10'
      ],
      gridRow: 1,
      letterSpacing: [0, 0, 0, 0, 0, '0.02em']
    }}
  />
);

const TextBlock = ({ designatedRef, heading, paragraph, sx }) => (
  <Box
    ref={designatedRef}
    className="facts__text-block"
    sx={{
      ...sx
    }}>
    <Heading as="h3" type="h3-500-125" variant="bold">
      {heading}
    </Heading>
    <Paragraph
      type="p-500-150-1"
      sx={{ marginTop: ['13px', '13px', '13px', '13px', '13px', '11px'] }}>
      {paragraph}
    </Paragraph>
  </Box>
);

const SectionDecorationSet = () => {
  const starRef = React.useRef(null);
  const triangleRef = React.useRef(null);

  React.useEffect(() => {
    const killOne = animateVectorParallax(starRef, 0, 0, 360);
    const killTwo = animateVectorParallax(triangleRef, -20, 100, 80);

    return () => {
      killOne();
      killTwo();
    };
  }, []);

  return (
    <>
      <Vector
        vectorRef={starRef}
        className="facts__red-star"
        svg={redStarSvg}
        alt=""
        sx={{
          gridColumn: [
            '9 / span 3',
            '8 / span 2',
            '19 / span 3',
            '19 / span 2',
            '17 / span 2',
            '21 / span 2'
          ],
          gridRow: [2, 2, 3, 1, 1, 2],
          justifySelf: ['end', 'end', 'end', 'end', 'center', 'start'],
          alignSelf: [null, null, null, 'start', 'start', 'start'],
          width: ['70%', '80%', '100%', '100%', '70%', '60%'],
          marginTop: ['32px', '40px', '70px', '14px', '120px', '71px']
        }}
      />
      <Vector
        vectorRef={triangleRef}
        className="facts__yellow"
        svg={yellowTriangleSvg}
        alt=""
        sx={{
          gridColumn: [
            '1 / span 3',
            '1 / span 2',
            '1 / span 5',
            '1 / span 4',
            '1 / span 3',
            '1 / span 3'
          ],
          gridRow: [7, 6, 6, 4, 4, 3],
          justifySelf: ['end', 'end', 'end', 'end', 'start', 'end'],
          width: ['180%', '210%', '100%', '100%', '90%', '123%'],
          marginTop: ['33px', '71px', '109px', '32px', '18px', '114px']
        }}
      />
    </>
  );
};

//===== list style ================
const factListStyles = [
  {
    gridColumn: [
      '1 / span 12',
      '2 / span 8',
      '3 / span 13',
      '1 / span 11',
      '3 / span 9',
      '5 / span 7'
    ],
    gridRow: [3, 3, 3, 2, 2, 2],
    marginTop: ['32px', '64px', '52px', '87px', '48px', '74px']
  },
  {
    gridColumn: [
      '1 / span 12',
      '3 / span 8',
      '5 / span 13',
      '14 / span 11',
      '14 / span 9',
      '14 / span 7'
    ],
    gridRow: [4, 4, 4, 2, 2, 2],
    marginTop: ['68px', '72px', '72px', '87px', '48px', '74px']
  },
  {
    gridColumn: [
      '1 / span 12',
      '4 / span 8',
      '7 / span 13',
      '1 / span 11',
      '3 / span 9',
      '5 / span 7'
    ],
    gridRow: [5, 5, 5, 3, 3, 3],
    marginTop: ['68px', '72px', '72px', '41px', '88px', '80px']
  },
  {
    gridColumn: [
      '1 / span 12',
      '5 / span 8',
      '9 / span 13',
      '14 / span 11',
      '14 / span 9',
      '14 / span 7'
    ],
    gridRow: [6, 6, 6, 3, 3, 3],
    marginTop: ['68px', '72px', '72px', '41px', '88px', '80px']
  }
];
