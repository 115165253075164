import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

export const animateTeamSection = (refs) => {
  const elements = [
    refs.sumRef.current,
    refs.functionRef.current,
    refs.zeroRef.current,
    refs.asteriskRef.current,
    refs.plusRef.current,
    refs.equalRef.current
  ];

  let tl = gsap.timeline({
    defaults: { ease: 'power3.out', duration: 0.8 },
    scrollTrigger: {
      trigger: refs.teamSectionRef.current,
      start: 'center center'
    }
  });
  tl.from(
    elements,
    {
      y: 0,
      x: 0,
      immediateRender: true,
      onComplete: () => {
        gsap.set(elements, { clearProps: 'all' });
      }
    },
    0
  ).fromTo(
    [
      refs.textBlockRef.current,
      refs.damianRef.current,
      refs.caroRef.current,
      refs.amriRef.current
    ],
    { autoAlpha: 0, scale: 0 },
    {
      autoAlpha: 1,
      scale: 1,
      onComplete: () => {
        gsap.set(
          [
            refs.textBlockRef.current,
            refs.damianRef.current,
            refs.caroRef.current,
            refs.amriRef.current
          ],
          { clearProps: 'all' }
        );
      }
    },
    '<'
  );

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};
