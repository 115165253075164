import React from 'react';

// pieces
import { Box } from '@thepuzzlers/pieces';

// components
import { RiveWrapper, LottieWrapper } from 'components';

// lotties
import dartsLottie from '../../../lotties/darts.json';
import laptopLottie from '../../../lotties/laptop.json';

export const StrategyIllustration = () => (
  <Box
    sx={{
      gridColumn: [
        '7/span 4',
        '7/span 4',
        '11/span 6',
        '12/span 5',
        '10/span 5',
        '7/span 3'
      ],
      gridRow: [1, 5, '5/span 2', 1, 1, 1],
      width: ['100%', '87%', '100%', '90%', '91%', '100%']
    }}>
    <LottieWrapper
      animationData={dartsLottie}
      sx={{
        m: [
          '-10% -130% -25% -24%',
          '-10% -130% -25% -24%',
          '-10% -130% -25% -24%',
          '-24% -130% -25% -24%',
          '-24% -130% -25% -24%',
          '-24% -130% -25% -24%'
        ]
      }}
    />
  </Box>
);

export const DesignIllustration = () => (
  <RiveWrapper
    sx={{
      gridColumn: [
        '7/span 6',
        '9/span 4',
        '13/span 8',
        '14/span 7',
        '12/span 6',
        '11/span 6'
      ],
      gridRow: [1, 4, '4/span 3', 4, '5/span 3', '1/span 5'],
      pt: '100%'
    }}
    riveSrc="branding.riv"
  />
);

export const DevelopmentIllustration = () => (
  <Box
    sx={{
      gridColumn: [
        '4/span 6',
        '2/span 5',
        '1/span 8',
        '1/span 8',
        '1/span 7',
        '1/span 5'
      ],
      gridRow: [1, 5, 5, 5, 5, 5]
    }}>
    <LottieWrapper
      animationData={laptopLottie}
      sx={{ m: '-26% -30% -26% -15%' }}
    />
  </Box>
);
