import { gsap } from 'gsap/all';

export const animateListItems = (listItems) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: listItems[0].current,
      start: 'top 80%'
    }
  });

  listItems.map((item, index) => {
    let start = `0.${index + 1}`;

    return tl.fromTo(
      item.current,
      {
        y: 20,
        autoAlpha: 0
      },
      {
        y: 0,
        autoAlpha: 1,
        duration: 0.6,
        ease: 'Power1.easeOut'
      },
      start
    );
  });

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};
