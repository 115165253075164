import { gsap } from 'gsap/all';

export const animateVectorParallax = (vector, x, y, rotation) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: vector.current,
      start: 'center bottom',
      end: 'bottom top',
      scrub: 0.5
    }
  });

  tl.to(vector.current, {
    xPercent: x,
    yPercent: -y,
    rotate: rotation,
    ease: 'none'
  });

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};


export const fadeInText = (element) => {
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: element.current,
      start: 'top 80%'
    }
  });

  tl.fromTo(
    element.current,
    { autoAlpha: 0, y: 20 },
    { autoAlpha: 1, y: 0, duration: 0.6, ease: 'Power1.easeOut' }
  );

  const killAnimation = () => {
    tl.scrollTrigger.kill(true);
    tl.pause(0).kill(true);
  };

  return killAnimation;
};