import React from 'react';

// pieces
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';

// components
import { Vector } from 'components';

// animations
import { fadeInText } from 'sections/animations';

export const Diversity = ({
  data: { heading, subHeading, modemSvg, paragraph }
}) => {
  return (
    <Section
      id="diversity"
      sx={{
        paddingBottom: ['121px', '181px', '265px', '187px', '176px', '15%']
      }}>
      <Headline heading={heading} />
      <SubHeadline subHeading={subHeading} />
      <ModemIllustration svg={modemSvg.publicURL} />
      <TextBlock paragraph={paragraph} />
    </Section>
  );
};

const Headline = ({ heading }) => (
  <Heading
    className="diversity_headline"
    as="h2"
    type="h2-500-125"
    dangerouslySetInnerHTML={{ __html: heading }}
    sx={{
      gridColumn: [
        '1 / span 11',
        '1 / span 10',
        '1 / span 19',
        '1 / span 12',
        '2 / span 13',
        '3 / span 11'
      ],
      gridRow: 1,
      letterSpacing: [0, 0, 0, 0, 0, '0.02em']
    }}
  />
);

const SubHeadline = ({ subHeading }) => {
  const textRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = fadeInText(textRef);

    return killAnimation;
  }, []);

  return (
    <Paragraph
      ref={textRef}
      type="p-500-150-1"
      dangerouslySetInnerHTML={{ __html: subHeading }}
      sx={{
        gridColumn: [
          '1 / span 8',
          '1 / span 5',
          '2 / span 10',
          '2 / span 9',
          '2 / span 7',
          '3 / span 5'
        ],
        gridRow: 2,
        marginTop: ['49px', '44px', '101px', '49px', '48px', '24.8%']
      }}
    />
  );
};

const ModemIllustration = ({ svg }) => (
  <Vector
    className="diversity__modem-illustration"
    svg={svg}
    alt=""
    sx={{
      gridColumn: [
        '3 / span 10',
        '3 / span 8',
        '7 / span 11',
        '8 / span 10',
        '8 / span 10',
        '9 / span 8'
      ],
      gridRow: [3, 3, 3, 3, 3, 2],
      marginTop: ['69px', '32px', '40px', '12px', '24px', '15.15%'],
      alignSelf: [null, null, null, null, null, 'start'],
      paddingLeft: [0, 0, 0, '5%', 0],
      paddingRight: [0]
    }}
  />
);

const TextBlock = ({ paragraph }) => {
  const textRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = fadeInText(textRef);

    return killAnimation;
  }, []);

  return (
    <Paragraph
      ref={textRef}
      className="diversity__paragraph"
      type="p-500-150-1"
      dangerouslySetInnerHTML={{ __html: paragraph }}
      sx={{
        gridColumn: [
          '1 / span 12',
          '6 / span 7',
          '12 / span 12',
          '13 / span 11',
          '15 / span 9',
          '18 / span 6'
        ],
        gridRow: [4, 4, 4, 4, 4, 2],
        marginTop: ['89px', '48px', '48px', '24px', '48px', '79.55%'],
        paddingRight: ['2%', '0%', '0%', '0%', '0%', '0%']
      }}
    />
  );
};
