import React from 'react';

// pieces
import { Box, FlexWrapper } from '@thepuzzlers/pieces';

// components
import { Vector, RiveWrapper } from 'components';

// self components
import { IndustriesBlobAndText } from './IndustriesBlobAndText';

// assets
import curlyArrow from 'assets/elements/curly-arrow.svg';
import globe from 'assets/illustrations/globe.svg';
import mobilityCircle from 'assets/illustrations/mobility-circle.svg';
import scooter from 'assets/illustrations/renewable-energy.svg';
import energyCircle from 'assets/illustrations/energy-circle.svg';
import windmill from 'assets/illustrations/windmill.svg';
import housingCircle from 'assets/illustrations/housing-circle.svg';
import housing from 'assets/illustrations/modular-housing.svg';

export const IllustrationWrapper = ({ industries: { examples } }) => (
  <Box
    sx={{
      display: 'grid',
      gridColumn: [
        '1/span 12',
        '3/span 8',
        '3/span 18',
        '10/span 15',
        '12/span 12',
        '13/span 10'
      ],
      gridRow: [1, 1, 1, '1/span 6', '1/span 6', '1/span 7'],
      gridTemplateColumns: [
        '1fr 1fr',
        '1fr 1fr',
        '1.5fr 2fr',
        '1.5fr 2fr',
        '1.5fr 2fr',
        '1.5fr 2fr'
      ]
    }}>
    <Vector
      className="globe"
      svg={globe}
      alt=""
      sx={{ gridColumn: ['1/3', '1/3', 2, 2, 2, 2] }}
      animate={{
        opacity: 1,
        rotateZ: [-10, 10, -10],
        scale: [0.9, 1, 0.9],

        transition: {
          opacity: { duration: 0 },
          default: {
            type: 'tween',
            ease: 'linear',
            duration: 100,
            loop: Infinity
          }
        }
      }}
      initial={{ opacity: 0 }}
    />
    <Mobility text={examples[0].name} />
    <RenewableEnergy text={examples[1].name} />
    <ModularHousing text={examples[2].name} />
  </Box>
);

const Mobility = ({ text }) => (
  <Box
    className="mobility"
    sx={{
      display: 'grid',
      gridRow: 1,
      gridColumn: [1, 1, '1/span 2', '1/span 2', '1/span 2', '1/span 2'],
      gridTemplateColumns: [
        null,
        null,
        '5fr 3fr',
        '5fr 3fr',
        '5fr 3fr',
        '5fr 3fr'
      ],
      transform: [
        'unset',
        'unset',
        'translateY(38%)',
        'translateY(38%)',
        'translateY(38%)',
        'translateY(38%)'
      ],
      width: ['100%', '100%', '52.5%', '52.5%', '52.5%', '52.5%']
    }}>
    <Vector
      svg={mobilityCircle}
      alt=""
      sx={{
        width: ['60%', '60%', '89%', '89%', '89%', '89%'],
        gridRow: 1,
        gridColumn: 1,
        transform: 'translatey(10%)'
      }}
    />
    <Vector
      svg={scooter}
      alt="Scooter illustration"
      sx={{
        gridRow: 1,
        gridColumn: 1,
        justifySelf: [null, null, 'end', 'end', 'end', 'end'],
        transform: [
          'translateX(30%)',
          'translateX(30%)',
          'unset',
          'unset',
          'unset',
          'unset'
        ],
        width: ['60%', '60%', '80%', '80%', '80%', '80%'],
        zIndex: 1
      }}
    />

    <FlexWrapper
      className="mobility-arrow"
      sx={{
        alignSelf: [null, null, 'center', 'center', 'center', 'center'],
        gridRow: [2, 2, 1, 1, 1, 1],
        flexDirection: [
          'row',
          'row',
          'column-reverse',
          'column-reverse',
          'column-reverse',
          'column-reverse'
        ],
        transform: [
          'translate(10%, 10%)',
          'translate(10%, 10%)',
          'unset',
          'unset',
          'unset',
          'unset'
        ]
      }}>
      <Vector
        svg={curlyArrow}
        alt=""
        sx={{
          alignSelf: [null, null, 'center', 'center', 'center', 'center'],
          transform: [
            'unset',
            'unset',
            'rotate(-80deg)scaleX(-1)',
            'rotate(-80deg)scaleX(-1)',
            'rotate(-80deg)scaleX(-1)',
            'rotate(-80deg)scaleX(-1)'
          ],
          width: [null, null, '35%', '35%', '35%', '35%']
        }}
      />
      <IndustriesBlobAndText
        text={text}
        sx={{ mb: [0, 0, '-10%', '-10%', '-10%', '-10%'] }}
      />
    </FlexWrapper>
  </Box>
);

const RenewableEnergy = ({ text }) => (
  <Box
    className="renewable-energy"
    sx={{
      display: 'grid',
      gridRow: 1,
      gridColumn: 2,
      transform: [
        'translateY(40%)',
        'translateY(40%)',
        'translateY(36%)',
        'translateY(36%)',
        'translateY(36%)',
        'translateY(36%)'
      ]
    }}>
    <Vector
      svg={energyCircle}
      alt=""
      sx={{
        width: ['40%', '40%', '23%', '23%', '23%', '23%'],
        gridRow: 2,
        gridColumn: 1,
        justifySelf: ['center', 'center', 'end', 'end', 'end', 'end'],
        transform: [
          'translate(20%, 40%)',
          'translate(20%, 40%)',
          'translate(0%, 40%)',
          'translate(0%, 40%)',
          'translate(0%, 40%)',
          'translate(0%, 40%)'
        ]
      }}
    />
    <Vector
      svg={windmill}
      alt="Windmill illustration"
      sx={{
        width: ['50%', '50%', '31%', '31%', '31%', '31%'],
        gridRow: 2,
        gridColumn: 1,
        justifySelf: ['center', 'center', 'end', 'end', 'end', 'end'],
        transform: [
          null,
          null,
          'translateX(-5%)',
          'translateX(-5%)',
          'translateX(-5%)',
          'translateX(-5%)'
        ],
        zIndex: 1
      }}
    />
    {/* <RiveWrapper
      sx={{
        width: ['50%', '50%', '31%', '31%', '31%', '31%'],
        gridRow: 2,
        gridColumn: 1,
        justifySelf: ['center', 'center', 'end', 'end', 'end', 'end'],
        transform: [
          null,
          null,
          'translateX(-5%)',
          'translateX(-5%)',
          'translateX(-5%)',
          'translateX(-5%)'
        ],
        zIndex: 1
      }}
      riveSrc="windmill.riv"
    /> */}

    <IndustriesBlobAndText
      text={text}
      sx={{
        gridRow: 1,
        gridColumn: 1,
        justifySelf: ['center', 'center', 'end', 'end', 'end', 'end'],
        transform: 'translateY(-12px)'
      }}
    />
  </Box>
);

const ModularHousing = ({ text }) => (
  <Box
    className="modular-housing"
    sx={{
      display: 'grid',
      gridRow: 3,
      gridColumn: ['1/3', '1/3', 2, 2, 2, 2],
      gridTemplateColumns: [
        '1fr 1fr',
        '1fr 1fr',
        'repeat(4, 1fr)',
        'repeat(4, 1fr)',
        'repeat(4, 1fr)',
        'repeat(4, 1fr)'
      ],
      position: 'relative',
      zIndex: 1
    }}>
    <Vector
      svg={housingCircle}
      alt=""
      sx={{
        width: ['60%', '60%', '71%', '71%', '71%', '71%'],
        gridRow: 1,
        gridColumn: [1, 1, '1/span 2', '1/span 2', '1/span 2', '1/span 2'],
        justifySelf: 'end',
        mt: ['-30%', '-30%', '-39.5%', '-39.5%', '-39.5%', '-39.5%']
      }}
    />
    <Vector
      svg={housing}
      alt="House illustration"
      sx={{
        width: ['60%', '60%', '68%', '68%', '68%', '68%'],
        gridRow: 1,
        gridColumn: [1, 1, '1/span 2', '1/span 2', '1/span 2', '1/span 2'],
        mt: '-20%',
        zIndex: 1,
        justifySelf: 'end',
        transform: [
          'translateX(-20%)',
          'translateX(-20%)',
          'translateX(-24%)',
          'translateX(-24%)',
          'translateX(-24%)',
          'translateX(-24%)'
        ]
      }}
    />

    <IndustriesBlobAndText
      text={text}
      sx={{
        gridRow: [1, 1, 2, 2, 2, 2],
        gridColumn: [2, 2, '2/span 3', '2/span 3', '2/span 3', '2/span 3'],
        ml: ['4px', '4px', '9%', '9%', '9%', '9%'],
        mt: [0, 0, '5%', '5%', '5%', '5%']
      }}
    />
  </Box>
);
