import React from 'react';

// pieces
import { Heading, FlexWrapper } from '@thepuzzlers/pieces';

// components
import { Vector } from 'components';

// assets
import blob from 'assets/elements/small-blob-start.svg';

export const IndustriesBlobAndText = ({ text, sx }) => (
  <FlexWrapper sx={{ alignItems: 'center', ...sx }}>
    <Vector svg={blob} alt="" sx={{ display: 'flex', mr: '8px', width: '16px' }} />
    <Heading as="h4" type="h4-500-100" variant="bold">
      {text}
    </Heading>
  </FlexWrapper>
);
