import React from 'react';

// pieces
import {
  Section,
  Heading,
  Paragraph,
  Box,
  GridWrapper
} from '@thepuzzlers/pieces';

// vector shapes
import {
  HalfCircleShape,
  TriangleShape,
  PlusShape,
  CircleShape,
  SumShape
} from './Shapes';

// illustrations
import {
  StrategyIllustration,
  DesignIllustration,
  DevelopmentIllustration
} from './Illustrations';

// animations
import { animateListItems } from './animations';

export const Services = ({ data: { heading, services } }) => {
  return (
    <Section
      id="services"
      sx={{
        gridTemplateRows: [
          'auto 11vh auto 16vh auto 11vh auto',
          'auto 7.4vh auto 19vh auto 23vh auto',
          'auto 12.2vh auto 18vh auto 20vh auto',
          'auto auto auto 42vh auto 22vh auto',
          'auto auto auto 25vh auto 21.5vh auto',
          'auto auto auto 220px auto 204px auto'
        ]
      }}>
      <MainHeadline heading={heading} />
      <StrategyWrapper services={services[0]} />
      <DesignWrapper services={services[1]} />
      <DevelopmentWrapper services={services[2]} />
    </Section>
  );
};

const MainHeadline = ({ heading }) => (
  <Heading
    as="h2"
    type="h2-900-125"
    dangerouslySetInnerHTML={{
      __html: heading
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '1/span 9',
        '1/span 15',
        '4/span 10',
        '3/span 12',
        '5/span 10'
      ],
      gridRow: [1, 1, 1, '1/span 2', '1/span 2', '1/span 2']
    }}
  />
);

/* ---------------- Reused components ---------------- */

const ServiceHeadline = ({ heading, devel, sx }) => (
  <Heading
    as="h3"
    type="h3-500-100"
    sx={{
      alignSelf: 'end',
      gridColumn: !devel
        ? [
            '1/span 5',
            '1/span 4',
            '1/span 6',
            '1/span 4',
            '1/span 4',
            '1/span 3'
          ]
        : [
            '1/span 8',
            '1/span 5',
            '1/span 9',
            '1/span 7',
            '1/span 6',
            '1/span 5'
          ],
      ...sx
    }}>
    {heading}
  </Heading>
);

const ServiceText = ({ text, sx }) => (
  <Paragraph
    as="p"
    type="p-500-150"
    dangerouslySetInnerHTML={{
      __html: text
    }}
    sx={{
      gridColumn: [
        '1/span 11',
        '1/span 7',
        '1/span 13',
        '1/span 11',
        '1/span 9',
        '1/span 8'
      ],
      ...sx
    }}
  />
);

const ListWrapper = ({ list: { heading, items }, listItemsRef, sx }) => (
  <Box sx={{ ...sx }}>
    <Paragraph as="p" type="body-500" variant="bold">
      {heading}
    </Paragraph>
    <Box as="ul" sx={{ display: 'flex', flexWrap: 'wrap' }}>
      {items.map((item, index) => {
        return (
          <ListItem
            key={item.name}
            designatedRef={listItemsRef.current[index]}
            text={item.name}
          />
        );
      })}
    </Box>
  </Box>
);

const ListItem = ({ designatedRef, text }) => (
  <Box
    as="li"
    ref={designatedRef}
    variant="typography.h6-500.bold"
    sx={{
      border: (t) => `2px solid ${t.colors.blue}`,
      borderRadius: 'listItem',
      p: '8px 16px',
      mt: '12px',
      mr: '12px',
      whiteSpace: 'nowrap',
      width: 'fit-content'
    }}>
    {text}
  </Box>
);

/* ------------------- Main Wrappers ------------------- */

const StrategyWrapper = ({ services: { heading, text, list } }) => {
  const listItemsRef = React.useRef([]);

  listItemsRef.current = list.items.map(
    (ref, i) => listItemsRef.current[i] ?? React.createRef()
  );

  React.useEffect(() => {
    const killAnimation = animateListItems(listItemsRef.current);

    return killAnimation;
  }, []);

  return (
    <GridWrapper
      className="strategy-wrapper"
      variant="inside.autoColumns"
      sx={{
        gridTemplateRows: [
          'auto 16px auto 5.5vh auto',
          'auto 16px auto 3.5vh auto',
          'auto 12px auto 3.6vh auto 1fr',
          'auto auto 16px auto 12vh auto',
          'auto auto 16px auto 5vh auto',
          'auto auto 16px auto 5vh auto'
        ],
        gridColumn: [
          '1/span 11',
          '3/span 10',
          '8/span 16',
          '7/span 16',
          '9/span 14',
          '11/span 9'
        ],
        gridRow: [3, 3, 3, '2/span 2', '2/span 2', '2/span 2']
      }}>
      {/* Vector shape */}
      <HalfCircleShape />
      {/* Illustration */}
      <StrategyIllustration />
      {/* Info */}
      <ServiceHeadline heading={heading} sx={{ gridRow: [1, 1, 1, 2, 2, 2] }} />
      <ServiceText text={text} sx={{ gridRow: [3, 3, 3, 4, 4, 4] }} />
      <ListWrapper
        list={list}
        listItemsRef={listItemsRef}
        sx={{
          gridColumn: [
            '1/span 9',
            '1/span 6',
            '1/span 8',
            '1/span 16',
            '1/span 12',
            '1/span 9'
          ],
          gridRow: [5, 5, 5, 6, 6, 6]
        }}
      />
    </GridWrapper>
  );
};

const DesignWrapper = ({ services: { heading, text, list } }) => {
  const listItemsRef = React.useRef([]);

  listItemsRef.current = list.items.map(
    (ref, i) => listItemsRef.current[i] ?? React.createRef()
  );

  React.useEffect(() => {
    const killAnimation = animateListItems(listItemsRef.current);

    return killAnimation;
  }, []);

  return (
    <GridWrapper
      className="design-wrapper"
      variant="inside.autoColumns"
      sx={{
        gridTemplateRows: [
          'auto 16px auto 5.5vh auto',
          'auto 16px auto auto',
          'auto 12px auto 4vh auto 1fr',
          'auto 16px auto auto',
          'auto 16px auto auto auto 1fr',
          'auto 16px auto 5vh auto'
        ],
        gridColumn: [
          '1/span 12',
          '1/span 12',
          '1/span 20',
          '2/span 20',
          '3/span 17',
          '4/span 16'
        ],
        gridRow: [5, 5, 5, 5, 5, 5]
      }}>
      {/* Illustration */}
      <DesignIllustration />
      {/* Info */}
      <ServiceHeadline heading={heading} sx={{ gridRow: [1, 1, 1, 1, 1, 1] }} />
      <ServiceText text={text} sx={{ gridRow: [3, 3, 3, 3, '3/span 3', 3] }} />
      <ListWrapper
        listItemsRef={listItemsRef}
        list={list}
        sx={{
          alignSelf: ['start', 'center', 'start', 'center', 'center', 'start'],
          gridColumn: [
            '1/span 12',
            '1/span 8',
            '1/span 11',
            '1/span 12',
            '1/span 8',
            '1/span 6'
          ],
          gridRow: [5, 4, 5, 4, '6/span 2', 5]
        }}
      />
      {/* Vector shapes */}
      <TriangleShape />
      <PlusShape />
    </GridWrapper>
  );
};

const DevelopmentWrapper = ({ services: { heading, text, list } }) => {
  const listItemsRef = React.useRef([]);

  listItemsRef.current = list.items.map(
    (ref, i) => listItemsRef.current[i] ?? React.createRef()
  );

  React.useEffect(() => {
    const killAnimation = animateListItems(listItemsRef.current);

    return killAnimation;
  }, []);

  return (
    <GridWrapper
      className="development-wrapper"
      variant="inside.autoColumns"
      sx={{
        gridTemplateRows: [
          'auto 9vh auto 3vh auto 5.5vh auto',
          'auto 16px auto 31px auto',
          'auto 12px auto 3.6vh auto 1fr',
          'auto 16px auto 12vh auto',
          'auto 16px auto 5vh auto',
          'auto 16px auto 6vh auto'
        ],
        gridColumn: [
          '1/span 11',
          '1/span 12',
          '3/span 20',
          '5/span 18',
          '6/span 15',
          '13/span 11'
        ],
        gridRow: [7, 7, 7, 7, 7, 7]
      }}>
      {/* Illustration */}
      <DevelopmentIllustration />
      {/* Info */}
      <ServiceHeadline
        heading={heading}
        devel
        sx={{ gridRow: [3, 1, 1, 1, 1, 1] }}
      />
      <ServiceText text={text} sx={{ gridRow: [5, 3, 3, 3, 3, 3] }} />
      <ListWrapper
        listItemsRef={listItemsRef}
        list={list}
        devel
        sx={{
          alignSelf: [
            'start',
            'center',
            'center',
            'center',
            'center',
            'center'
          ],
          gridColumn: [
            '1/span 8',
            '8/span 5',
            '13/span 8',
            '11/span 8',
            '10/span 5',
            '7/span 4'
          ],
          gridRow: [7, 5, 5, 5, '5/span 2', 5]
        }}
      />
      {/* Vector shapes */}
      <CircleShape />
      <SumShape />
    </GridWrapper>
  );
};
