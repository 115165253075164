import React from 'react';

// pieces
import {
  Section,
  Heading,
  Paragraph,
  GridWrapper,
  Box
} from '@thepuzzlers/pieces';

// components
import { TargetGroupVector } from 'components';

// animations
import { fadeInText } from 'sections/animations';

export const TargetGroup = ({ data: { heading, paragraph } }) => {
  return (
    <Section id="targetGroup">
      <GridWrapper
        variant="inside.autoColumns"
        sx={{
          bg: 'blue',
          borderRadius: 'box',
          gridColumn: [
            '1/span 12',
            '1/span 12',
            '1/span 24',
            '1/span 24',
            '1/span 24',
            '2/span 22'
          ],
          gridTemplateRows: [
            '6vh auto 7vh auto 7vh auto',
            '6vh auto 5.6vh auto 5.6vh auto',
            '7.5vh auto 6.8vh 2fr 4fr',
            '7.5vh auto 5.2vh 1fr 7fr',
            '8.8vh auto 6.5vh 1fr 8fr',
            '12vh auto 7vh auto auto'
          ],
          overflow: 'hidden'
        }}>
        <Headline heading={heading} />
        <Text paragraph={paragraph} />
        <VectorGroup />
      </GridWrapper>
    </Section>
  );
};

const Headline = ({ heading }) => (
  <Heading
    as="h2"
    type="h2-700-125"
    dangerouslySetInnerHTML={{
      __html: heading
    }}
    sx={{
      color: 'biege',
      gridColumn: [
        '2/span 10',
        '3/span 8',
        '6/span 15',
        '11/span 12',
        '11/span 10',
        '11/span 8'
      ],
      gridRow: 2
    }}
  />
);

const Text = ({ paragraph }) => {
  const textRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = fadeInText(textRef);

    return killAnimation;
  }, []);

  return (
    <Paragraph
      as="p"
      type="p-500-150"
      ref={textRef}
      dangerouslySetInnerHTML={{
        __html: paragraph
      }}
      sx={{
        color: 'biege',
        gridColumn: [
          '2/span 10',
          '4/span 7',
          '9/span 11',
          '11/span 9',
          '11/span 10',
          '11/span 7'
        ],
        gridRow: [4, 4, '4/span 2', '4/span 2', '4/span 2', 4],
        letterSpacing: '0.02em'
      }}
    />
  );
};

const VectorGroup = () => (
  <Box
    sx={{
      gridColumn: [
        '1/span 8',
        '1/span 6',
        '1/span 8',
        '1/span 8',
        '1/span 8',
        '1/span 7'
      ],
      gridRow: [6, 6, 5, 5, 5, '3/span 3'],
      ml: ['-40%', '-26%', '-29%', '-26%', '-26%', '-18%'],
      '& > svg': {
        display: 'block',
        height: '100%',
        width: '100%'
      }
    }}>
    <TargetGroupVector />
  </Box>
);
