import React from 'react';

// pieces
import { Section, Heading } from '@thepuzzlers/pieces';

// components
import { Vector } from 'components';

// self components
import { IndustriesBlobAndText } from './IndustriesBlobAndText';
import { IllustrationWrapper } from './IllustrationsWrapper';

// assets
import equal from 'assets/elements/equal.svg';
import zero from 'assets/elements/zero.svg';

// animations
import { animateVectorParallax } from 'sections/animations';

export const Intro = ({ data: { heading, industries } }) => (
  <Section
    id="intro"
    sx={{
      gridTemplateRows: [
        'auto 8.8vh auto 3vh auto 3vh auto 6vh auto',
        'auto 7.2vh auto 2.7vh auto 3.6vh auto 7vh auto',
        'auto 7.5vh auto 2.8vh auto 3.75vh auto 4vh auto',
        'auto auto 5.8vh auto 5.8vh auto 9vh auto',
        'auto auto 8.5vh auto 4.25vh auto 16.2vh auto',
        'auto auto 8.7vh auto 4.4vh auto 11vh 8.4vh auto'
      ]
    }}>
    <IllustrationWrapper industries={industries} />
    <Equal />
    <Headline heading={heading} />
    <SubHeadline industries={industries} />
    <Zero />
  </Section>
);

const Headline = ({ heading }) => (
  <Heading
    as="h2"
    type="h2-500-125"
    dangerouslySetInnerHTML={{
      __html: heading
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '1/span 10',
        '1/span 18',
        '1/span 11',
        '1/span 13',
        '2/span 10'
      ],
      gridRow: [5, 5, 5, 4, 4, 4]
    }}
  />
);

const SubHeadline = ({ industries: { heading } }) => (
  <IndustriesBlobAndText
    text={heading}
    sx={{
      gridColumn: [
        '1/span 12',
        '1/span 8',
        '1/span 12',
        '1/span 11',
        '1/span 8',
        '2/span 6'
      ],
      gridRow: [7, 7, 7, 6, 6, 6]
    }}
  />
);

const Equal = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, 0, 300, -20);

    return killAnimation;
  }, []);

  return (
    <Vector
      vectorRef={vectorRef}
      svg={equal}
      alt=""
      sx={{
        gridColumn: [
          '2/span 2',
          '2/span 2',
          '2/span 2',
          '2/span 2',
          '2/span 2',
          '4/span 2'
        ],
        gridRow: [3, 3, 3, 2, 2, 2],
        width: ['57%', '52%', '78%', '72%', '53%', '53%']
      }}
    />
  );
};

const Zero = () => {
  const vectorRef = React.useRef(null);

  React.useEffect(() => {
    const killAnimation = animateVectorParallax(vectorRef, 50, 300, 360);

    return killAnimation;
  }, []);

  return (
    <Vector
    vectorRef={vectorRef}
      svg={zero}
      alt=""
      sx={{
        gridColumn: [
          '10/span 1',
          '10/span 1',
          '20/span 2',
          '17/span 1',
          '15/span 1',
          '13/span 1'
        ],
        gridRow: [9, 9, 9, 8, 8, 9],
        justifySelf: ['start', 'start', 'start', 'start', 'center', 'start'],
        width: ['70%', '64%', '47%', '100%', '72%', '65%']
      }}
    />
  );
};
