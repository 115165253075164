import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// sections and data
import {
  useLandingPageData,
  Header,
  Intro,
  TargetGroup,
  Services,
  Facts,
  Diversity,
  Team
} from 'sections/landingPage';

// pieces
import { Box } from '@thepuzzlers/pieces';

import { TiltSpacer } from 'components';

const Home = () => {
  const { seo, header, intro, targetGroup, services, facts, diversity, team } =
    useLandingPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={header} />
      <Box
        className="spacer"
        sx={{ height: ['27vh', '15.5vh', '16.7vh', '42vh', '24vh', '22vh'] }}
      />
      <Intro data={intro} />
      <Box
        className="spacer"
        sx={{ height: ['27vh', '15.5vh', '20vh', '42vh', '24vh', '22vh'] }}
      />
      <TargetGroup data={targetGroup} />
      <TiltSpacer />
      <Services data={services} />
      <Box
        className="spacer"
        sx={{
          height: ['29.5vh', '23.5vh', '13.5vh', '30vh', '36vh', '30vh']
        }}
      />
      <Facts data={facts} />
      <Box
        className="spacer"
        sx={{ height: ['24vh', '23.5vh', '20vh', '20vh', '24vh', '30vh'] }}
      />
      <Team data={team} />
      <Box
        className="spacer"
        sx={{ height: ['29.5vh', '15.5vh', '13.5vh', '22vh', '17vh', '40vh'] }}
      />
      <Diversity data={diversity} />
    </>
  );
};
export default injectIntl(Home);
