import { graphql, useStaticQuery } from 'gatsby';

export const useLandingPageData = () => {
  const { data } = useStaticQuery(graphql`
    query {
      data: landingPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          heading
          info {
            country
            services
          }
        }
        intro {
          heading
          industries {
            heading
            examples {
              name
            }
          }
        }
        targetGroup {
          heading
          paragraph
        }
        services {
          heading
          services {
            heading
            text
            list {
              heading
              items {
                name
              }
            }
          }
        }
        facts {
          heading
          factList {
            heading
            paragraph
          }
        }
        diversity {
          heading
          subHeading
          modemSvg {
            publicURL
          }
          paragraph
        }
        team {
          heading
          paragraph
        }
      }
    }
  `);

  return data;
};
