import React from 'react';

// pieces
import {
  Section,
  Heading,
  FlexWrapper,
  Paragraph,
  Box
} from '@thepuzzlers/pieces';

// components
import { Vector, Spacer } from 'components';

// assets
import circle from 'assets/logo-elements/circle.svg';
import triangle from 'assets/logo-elements/triangle.svg';
import halfCircle from 'assets/logo-elements/half-circle.svg';
import arrow from 'assets/elements/arrow.svg';
import questionExclamation from 'assets/illustrations/question-exclamation-mark.svg';

// animations
import { introHeaderAnimation } from './animations';

const gif =
  'https://media2.giphy.com/media/JWF7fOo3XyLgA/giphy.gif?cid=790b7611073f86013ea0af13c26cf595a3dedd291b0a9682&rid=giphy.gif&ct=g';

export const Header = ({ data: { heading, info } }) => {
  // State to add animation to "transform" text once the
  // headline is showing during header intro animation
  const [animateTextVariant, setAnimateTextVariant] = React.useState(false);

  // State to display gif on hover/click of question/exclamation illustration
  const [showGif, setShowGif] = React.useState(false);

  const headlineRef = React.useRef(null);
  const circleElementRef = React.useRef(null);
  const triangleElementRef = React.useRef(null);
  const halfCircleElementRef = React.useRef(null);
  const infoRef = React.useRef(null);
  const quesAndExcRef = React.useRef(null);
  const arrowRef = React.useRef(null);
  const gifRef = React.useRef(null);

  const refs = {
    headlineRef,
    circleElementRef,
    triangleElementRef,
    halfCircleElementRef,
    infoRef,
    quesAndExcRef,
    arrowRef
  };

  const handleShowGif = () => {
    setShowGif(!showGif);
  };

  const handleTextTransform = () => {
    setAnimateTextVariant(true);
  };

  React.useEffect(() => {
    const killAnimation = introHeaderAnimation(refs, handleTextTransform);

    return killAnimation;
  }, []);

  return (
    <Section
      as="header"
      id="header"
      sx={{
        gridTemplateRows: [
          null,
          null,
          null,
          'repeat(2, 1fr)',
          'repeat(2, 1fr)',
          'repeat(2, 1fr)'
        ],
        height: ['auto', 'auto', 'auto', '95vh', '95vh', '95vh'],
        minHeight: ['auto', 'auto', 'auto', '300px', '700px', '700px']
      }}>
      <Spacer
        sx={{
          gridRow: [1],
          mt: ['65%', '60%', '50%', 0, 0, 0],
          display: ['block', 'block', 'block', 'none']
        }}
      />
      <Headline
        designatedRef={headlineRef}
        heading={heading}
        animateTextVariant={animateTextVariant}
      />
      <Spacer
        sx={{
          gridRow: [3],
          mt: ['15%', '10%', '10%', 0, 0, 0],
          display: ['block', 'block', 'block', 'none']
        }}
      />
      <LogoElementsWrapper refs={refs} />
      <Spacer
        sx={{
          gridRow: [5],
          mt: ['15%', '10%', '10%', 0, 0, 0],
          display: ['block', 'block', 'block', 'none']
        }}
      />
      {/* Bottom part */}
      <InfoWrapper designatedRef={infoRef} info={info} />
      <Spacer
        sx={{
          gridRow: [7],
          mt: ['8%', '10%', '7%', 0, 0, 0],
          alignSelf: 'end',
          display: ['block', 'block', 'block', 'none']
        }}
      />
      <Arrow designatedRef={arrowRef} />
      <Spacer
        sx={{
          gridRow: [9],
          mt: ['13%', '10%', '7%', 0, 0, 0],
          alignSelf: 'end',
          display: ['block', 'block', 'block', 'none']
        }}
      />
      <IllustrationWrapper
        designatedRef={quesAndExcRef}
        onMouseEnter={handleShowGif}
        onMouseLeave={handleShowGif}
        onClick={handleShowGif}
      />
      <Gif designatedRef={gifRef} showGif={showGif} />
    </Section>
  );
};

const Headline = ({ designatedRef, heading, animateTextVariant }) => (
  <Heading
    as="h1"
    type="h1-500-135"
    ref={designatedRef}
    dangerouslySetInnerHTML={{
      __html: heading
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '1/span 12',
        '3/span 20',
        '7/span 12',
        '6/span 14',
        '7/span 12'
      ],
      width: ['100%', '90%', '100%', '100%', '100%', '100%'],
      gridRow: [2, 2, 2, '1/3', '1/3', '1/3'],
      alignSelf: 'center',
      justifySelf: 'center',
      textAlign: 'center',
      visibility: 'hidden',
      // '& > .transform-text': {
      //   fontFamily: 'freigeist-variable',
      //   fontVariationSettings: '"wght" 550, "wdth" 95',
      //   animation: animateTextVariant
      //     ? 'textTransform 2s ease 0s 1 normal forwards'
      //     : 'unset'
      // }
    }}
  />
);

const LogoElementsWrapper = ({ refs }) => (
  <FlexWrapper
    sx={{
      alignItems: 'center',
      gridColumn: [
        '5/span 4',
        '5/span 4',
        '11/span 4',
        '11/span 4',
        '11/span 4',
        '11/span 4'
      ],
      // mt: '200%',
      gridRow: [4, 4, 4, 2, 2, 2],
      alignSelf: 'center',
      justifyContent: 'space-around',
      justifySelf: 'center',
      width: ['100%', '64%', '100%', '100%', '85%', '70%']
    }}>
    <Vector
      vectorRef={refs.circleElementRef}
      svg={circle}
      alt=""
      sx={{ width: '18%', visibility: 'hidden' }}
    />
    <Vector
      vectorRef={refs.triangleElementRef}
      svg={triangle}
      alt=""
      sx={{ width: '25%', visibility: 'hidden' }}
    />
    <Vector
      vectorRef={refs.halfCircleElementRef}
      svg={halfCircle}
      alt=""
      sx={{ width: '18%', visibility: 'hidden' }}
    />
  </FlexWrapper>
);

const InfoWrapper = ({ designatedRef, info: { country, services } }) => (
  <Box
    ref={designatedRef}
    sx={{
      alignSelf: 'end',
      gridColumn: [
        '2/span 10',
        '4/span 6',
        '8/span 10',
        '1/span 8',
        '1/span 7',
        '1/span 5'
      ],
      gridRow: [6, 6, 6, 2, 2, 2],
      alignSelf: 'end',
      textAlign: ['center', 'center', 'center', 'left', 'left', 'left'],
      visibility: 'hidden'
    }}>
    <Paragraph as="p" type="h4-700-150">
      {country}
    </Paragraph>
    <Paragraph as="p" type="h4-700-150" variant="bold">
      {services}
    </Paragraph>
  </Box>
);

const Arrow = ({ designatedRef }) => (
  <Vector
    vectorRef={designatedRef}
    svg={arrow}
    alt="Arrow down icon"
    sx={{
      display: 'flex',
      alignItems: 'end',
      gridColumn: [
        '6/span 2',
        '6/span 2',
        '12/span 2',
        '12/span 2',
        '12/span 2',
        '12/span 2'
      ],
      gridRow: [8, 8, 8, 2, 2, 2],
      alignSelf: 'end',
      justifySelf: 'center',
      width: ['58%', '54%', '85%', '58%', '52%', '48%'],
      visibility: 'hidden'
    }}
  />
);

const IllustrationWrapper = ({ designatedRef, ...props }) => (
  <Vector
    vectorRef={designatedRef}
    svg={questionExclamation}
    alt="Question and exclamation marks illustration"
    sx={{
      alignSelf: 'end',
      gridColumn: [
        '11/span 2',
        '11/span 2',
        '22/span 3',
        '23/span 2',
        '23/span 2',
        '23/span 2'
      ],
      gridRow: [10, 10, 10, 2, 2, 2],
      alignSelf: 'end',
      justifySelf: 'end',
      width: ['80%', '76%', '78%', '76%', '70%', '59%'],
      visibility: 'hidden'
    }}
    {...props}
  />
);

const Gif = ({ designatedRef, showGif }) => (
  <Box
    ref={designatedRef}
    sx={{
      alignSelf: ['center', 'end', 'end', 'center', 'center', 'center'],
      backgroundImage: `url(${gif})`,
      backgroundPosition: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 'box',
      gridColumn: ['1/span 12', '3/span 8', '5/span 16', '6/span 14'],
      gridRow: [
        2,
        '1/span 4',
        '1/span 4',
        '1/span 2',
        '1/span 2',
        '1/span 2',
        '7/span 12'
      ],
      height: ['100%', '40%', '42%', '56%', '40%', '55%'],
      position: 'relative',
      pointerEvents: 'none',
      opacity: !showGif ? 0 : 1,
      transition: 'all 0.2s ease-in',
      zIndex: 4
    }}
  />
);
