import React from 'react';

// pieces
import {
  Section,
  Heading,
  Paragraph,
  GridItem,
  GridWrapper,
  Box
} from '@thepuzzlers/pieces';

// components
import { Vector } from 'components';

// assets
import sumSvg from 'assets/elements/sum-yellow.svg';
import functionSvg from 'assets/elements/function.svg';
import zeroSvg from 'assets/elements/zero.svg';
import asteriskSvg from 'assets/elements/asterisk-blue.svg';
import plusSvg from 'assets/elements/plus-yellow.svg';
import equalSvg from 'assets/elements/equal-red.svg';

import amri from 'assets/illustrations/portrait-amri.svg';
import caro from 'assets/illustrations/portrait-caro.svg';
import damian from 'assets/illustrations/portrait-damian.svg';

import { animateTeamSection } from './animations';

export const Team = ({ data: { heading, paragraph } }) => {
  const teamSectionRef = React.useRef();
  const textBlockRef = React.useRef();

  const damianRef = React.useRef();
  const caroRef = React.useRef();
  const amriRef = React.useRef();

  const sumRef = React.useRef();
  const functionRef = React.useRef();
  const zeroRef = React.useRef();
  const asteriskRef = React.useRef();
  const plusRef = React.useRef();
  const equalRef = React.useRef();

  const refs = {
    teamSectionRef,
    textBlockRef,
    damianRef,
    caroRef,
    amriRef,
    sumRef,
    functionRef,
    zeroRef,
    asteriskRef,
    plusRef,
    equalRef
  };

  React.useEffect(() => {
    let killAnimation = null;
    const animationTimeOut = setTimeout(
      () => (killAnimation = animateTeamSection(refs)),
      200 // because in firefox the css is loaded slower, so to make gsap get the correct initial value for the animation, we need to delay gsap call after css loaded fully
    );
    // const killAnimation = animateTeamSection(refs);
    // return killAnimation;
    return () => {
      clearTimeout(animationTimeOut);
      if (killAnimation) {
        killAnimation();
      }
    };
  }, []);

  return (
    <Section id="team" ref={teamSectionRef}>
      <Damian designatedRef={damianRef} />
      <Box
        sx={{
          gridRow: [2],
          mt: ['500%', '500%', '800%', '400%', '500%', '350%']
        }}
      />
      <TextBlock
        designatedRef={textBlockRef}
        heading={heading}
        paragraph={paragraph}
      />
      <Symbols refs={refs} />
      <Box
        sx={{
          gridRow: [4],
          mt: ['150%', '150%', '150%', 0, '50%', 0],
          display: ['block', 'block', 'block', 'none', 'block', 'none']
        }}
      />
      <Caro designatedRef={caroRef} />
      <Box
        sx={{
          gridRow: [6],
          mt: ['100%', '100%', '100%', '50%', '25%', '50%']
        }}
      />
      <Amri designatedRef={amriRef} />
    </Section>
  );
};

const Headline = ({ heading }) => (
  <Heading
    className="diversity-heading"
    as="h2"
    type="h2-700-125"
    dangerouslySetInnerHTML={{
      __html: heading
    }}
    sx={{
      gridColumn: [
        '1/span 12',
        '2/span 10',
        '5/span 16',
        '6/span 14',
        '5/span 16',
        '7/span 12'
      ],
      gridRow: 1,
      justifySelf: 'center',
      width: ['100%', '92%', '94%', '100%', '100%', '100%']
    }}
  />
);

const Text = ({ paragraph }) => (
  <Paragraph
    as="p"
    type="p-500-150-2"
    dangerouslySetInnerHTML={{
      __html: paragraph
    }}
    sx={{
      gridColumn: [
        '2/span 10',
        '2/span 10',
        '6/span 14',
        '6/span 14',
        '8/span 10',
        '9/span 8'
      ],
      gridRow: 3,
      justifySelf: 'center',
      width: ['100%', '95%', '100%', '100%', '100%', '100%']
    }}
  />
);

const TextBlock = ({ designatedRef, heading, paragraph }) => (
  <GridItem
    ref={designatedRef}
    sx={{
      alignSelf: 'center',
      gridRow: 3
    }}>
    <GridWrapper
      variant="inside.autoColumns"
      sx={{
        gridTemplateRows: [
          'auto 24px auto',
          'auto 24px auto',
          'auto 24px auto',
          'auto 16px auto',
          'auto 24px auto',
          'auto 24px auto'
        ],
        textAlign: 'center'
      }}>
      <Headline heading={heading} />
      <Text paragraph={paragraph} />
    </GridWrapper>
  </GridItem>
);

const Symbols = ({ refs }) => (
  <GridItem
    sx={{
      alignSelf: 'center',
      alignItems: 'center',
      display: 'flex',
      gridColumn: [
        '3/span 8',
        '4/span 6',
        '7/span 12',
        '9/span 8',
        '9/span 8',
        '10/span 6'
      ],
      gridRow: 3,
      justifyContent: 'space-around',
      zIndex: 1,
      '& > div': {
        width: ['11%', '11%', '11%', '10%', '11%', '11%']
      }
    }}>
    <Vector
      vectorRef={refs.sumRef}
      svg={sumSvg}
      alt="Sum symbol"
      sx={{
        transform: [
          'translate(-140%, -620%)',
          'translate(-180%, -700%)',
          'translate(-340%, -500%)',
          'translate(-680%, -450%)',
          'translate(-600%, -450%)',
          'translate(-660%, -430%)'
        ]
      }}
    />
    <Vector
      vectorRef={refs.functionRef}
      svg={functionSvg}
      alt="Function symbol"
      sx={{
        transform: [
          'translate(-60%, -840%)',
          'translate(-20%, -940%)',
          'translate(-100%, -620%)',
          'translate(-320%, -630%)',
          'translate(-100%, -660%)',
          'translate(-170%, -630%)'
        ]
      }}
    />
    <Vector
      vectorRef={refs.zeroRef}
      svg={zeroSvg}
      alt="Zero symbol"
      sx={{
        transform: [
          'translate(600%, -750%)',
          'translate(640%, -850%)',
          'translate(710%, -550%)',
          'translate(1050%, -520%)',
          'translate(1060%, -550%)',
          'translate(1270%, -530%)'
        ]
      }}
    />
    <Vector
      vectorRef={refs.asteriskRef}
      svg={asteriskSvg}
      alt="Asterisk symbol"
      sx={{
        transform: [
          'translate(-480%, 1220%)',
          'translate(-480%, 1320%)',
          'translate(-400%, 850%)',
          'translate(-660%, 970%)',
          'translate(-600%, 860%)',
          'translate(-690%, 900%)'
        ]
      }}
    />
    <Vector
      vectorRef={refs.plusRef}
      svg={plusSvg}
      alt="Plus symbol"
      sx={{
        transform: [
          'translate(280%, 900%)',
          'translate(280%, 900%)',
          'translate(410%, 580%)',
          'translate(720%, 600%)',
          'translate(650%, 560%)',
          'translate(810%, 540%)'
        ]
      }}
    />
    <Vector
      vectorRef={refs.equalRef}
      svg={equalSvg}
      alt="Equal symbol"
      sx={{
        transform: [
          'translate(-380%, 2350%)',
          'translate(-380%, 2550%)',
          'translate(-380%, 1750%)',
          'translate(-420%, 2000%)',
          'translate(-380%, 1750%)',
          'translate(-460%, 1800%)'
        ]
      }}
    />
  </GridItem>
);

const Amri = ({ designatedRef }) => (
  <Vector
    vectorRef={designatedRef}
    className="amri"
    svg={amri}
    alt=""
    sx={{
      alignSelf: 'end',
      gridColumn: [
        '9/span 3',
        '8/span 3',
        '16/span 4',
        '17/span 3',
        '15/span 3',
        '14/span 3'
      ],
      gridRow: 7,
      justifySelf: ['start', 'start', 'start', 'start', 'start', 'center'],
      width: ['88%', '77%', '90%', '100%', '90%', '78%']
    }}
  />
);

const Caro = ({ designatedRef }) => (
  <Vector
    vectorRef={designatedRef}
    className="caro"
    svg={caro}
    alt=""
    sx={{
      alignSelf: 'center',
      gridColumn: [
        '1/span 3',
        '2/span 3',
        '3/span 4',
        '3/span 4',
        '4/span 3',
        '4/span 3'
      ],
      gridRow: 5,
      justifySelf: ['start', 'start', 'center', 'start', 'start', 'start'],
      width: ['100%', '92%', '92%', '82%', '100%', '82%']
    }}
  />
);

const Damian = ({ designatedRef }) => (
  <Vector
    vectorRef={designatedRef}
    className="damian"
    svg={damian}
    alt=""
    sx={{
      alignSelf: 'baseline',
      gridColumn: [
        '7/span 3',
        '7/span 3',
        '14/span 4',
        '13/span 3',
        '15/span 3',
        '14/span 3'
      ],
      gridRow: 1,
      justifySelf: ['center', 'center', 'start', 'start', 'start', 'start'],
      width: ['84%', '74%', '79%', '71%', '71%', '60%']
    }}
  />
);
